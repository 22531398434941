@font-face {
  font-family: "Gopher";
  src: local("Gopher Medium Italic"), local("Gopher-Medium-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Heavy Italic"), local("Gopher-Heavy-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-HeavyItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Hairline"), local("Gopher-Hairline"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Hairline.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Heavy"), local("Gopher-Heavy"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Black Italic"), local("Gopher-Black-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Black"), local("Gopher-Black"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Bold Italic"), local("Gopher-Bold-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Bold"), local("Gopher-Bold"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Medium"), local("Gopher-Medium"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Italic"), local("Gopher-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Regular"), local("Gopher-Regular"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Light Italic"), local("Gopher-Light-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Light"), local("Gopher-Light"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Thin Italic"), local("Gopher-Thin-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Thin"), local("Gopher-Thin"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font_display: 'swap';
}
@font-face {
  font-family: "Gopher";
  src: local("Gopher Hairline Italic"), local("Gopher-Hairline-Italic"),
    url("https://cdn.shopify.com/s/files/1/0567/9434/9681/files/Gopher-HairlineItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font_display: 'swap';
}